import { ObjectInterface, PanoramaMotion } from "../interfaces";


export const UPDATE_DATE = "2024.10.11 [Scale error in map editor fixed]"

// export type Lv1MenuItemNames = 'Site Selector' | 'Data Viewer' | 'Map Builder' | 'Robot Operator'
// ['Site Selector', 'Data Viewer', 'Map Builder', 'Robot Operator']

// export type Lv1MenuItemNames = 'Site Selector' | 'Menu Selector'
// export const LV1_HEADER_MENUS_URL: Record<Lv1MenuItemNames, string> = {
//   'Site Selector': '/site',
//   'Menu Selector': '/menu',
// }

// export type Lv2MenuItemNames = 'Data Viewer' | 'Map Builder' | 'Robot Operator'
// export const LV2_HEADER_MENUS_URL: Record<Lv2MenuItemNames, string> = {
//   'Data Viewer': '/viewer',
//   'Map Builder': '/mapbuilder',
//   'Robot Operator': '/operator',
// }


export const MSGBOX_YESNO = ['Yes', 'No']
export const MSGBOX_OK = ['OK']
export const MSGBOX_CONFIRM = ['Confirm', 'Cancel']
export type msgBoxButtonTypes = typeof MSGBOX_YESNO | typeof MSGBOX_OK | typeof MSGBOX_CONFIRM

export type msgBoxColorNames = "normal" | "warning" | "danger"
export const MSGBOX_COLOR_CODE: Record<msgBoxColorNames, [bgColor: string, color: string]> = {
  normal: ['rgb(119,158,253)', "rgb(50,50,50)"],
  warning: ['rgb(223,195,83)', "rgb(50,50,50)"],
  danger: ['rgb(216,82,97)', "rgb(250,250,250)"],
}


export const ERROR_CODE_SESSION_OUT = 401

export const INIT_PANORAMA_MOTION: PanoramaMotion = { pitchDeg: 0, yawDeg: 0, hfovDeg: 120 }

// export const SESSION_TOKEN_TITLE = "gsenc.session_token";
// export const SESSION_SELECTED_SITE_DATA_ID = "gsenc.selected_site_data_id";

interface ActionItems {
  [name: string]: string;
}

export const ACTION_ITEMS: ObjectInterface = {
  'CAM_COMBINED_IMAGE': 'Front View',
  'SENSOR': 'Sensor',
  'CAM_COMBINED_VIDEO': 'Front Video',
  'CAM360_IMAGE': '360 Image',
  'CAM360_VIDEO': '360 Video',
  'THERMAL_MSG': 'Thermal Image',
  'VOICE_RECORDING': 'Voice',
}



interface SensorData {
  [name: string]: {
    htmltitle: string,
    htmlunit: string,
    maxDangerValue: number | undefined,
    maxWarningValue: number | undefined,
    minWarningValue: number | undefined,
    minDangerValue: number | undefined,
  };
}

/****출처
 * O2, H2S, CO : https://seumedu.kr/data/safe/%EB%B0%80%ED%8F%90%EA%B3%B5%EA%B0%84%20%EC%A7%88%EC%8B%9D%EC%9E%AC%ED%95%B4%EC%98%88%EB%B0%A9%20%EC%95%88%EC%A0%84%EC%9E%91%EC%97%85%20%EA%B0%80%EC%9D%B4%EB%93%9C.pdf
 * CH4:https://m.blog.naver.com/iltarzoa/223071426936
 * CO, SO2: https://www.me.go.kr/mamo/web/index.do?menuId=586
 * HCHO: http://www.seehint.com/word.asp?no=11534
*/
export const SENSOR_DATA: SensorData = {
  O2: {
    htmltitle: "O<sub>2</sub>",
    htmlunit: "%",
    maxDangerValue: undefined,
    maxWarningValue: 23,
    minWarningValue: 18,
    minDangerValue: 12,
  },
  // H2S: {
  //   htmltitle: "H<sub>2</sub>S",
  //   htmlunit: "ppm",
  //   maxDangerValue: 200,
  //   maxWarningValue: 50,
  //   minWarningValue: undefined,
  //   minDangerValue: undefined,
  // },
  CO: {
    htmltitle: "CO",
    htmlunit: "ppm",
    maxDangerValue: 25,
    maxWarningValue: 9,
    minWarningValue: undefined,
    minDangerValue: undefined,
  },
  CH4: {
    htmltitle: "CH<sub>4</sub>",
    htmlunit: "ppm",
    maxDangerValue: 200,
    maxWarningValue: undefined,
    minWarningValue: undefined,
    minDangerValue: undefined,

  },
  LPG: {
    htmltitle: "LPG",
    htmlunit: "ppm",
    maxDangerValue: 200,
    maxWarningValue: undefined,
    minWarningValue: undefined,
    minDangerValue: undefined,


  },
  // SO2: {
  //   htmltitle: "SO<sub>2</sub>",
  //   htmlunit: "%",
  //   maxDangerValue: 0.15,
  //   maxWarningValue: 0.05,
  //   minWarningValue: undefined,
  //   minDangerValue: undefined,
  // },
  // HCHO: {
  //   htmltitle: "HCHO",
  //   htmlunit: "ppm",
  //   maxDangerValue: 5,
  //   maxWarningValue: 0.75,
  //   minWarningValue: undefined,
  //   minDangerValue: undefined,
  // }
};


export const SENSOR_COLOR_CODE: ObjectInterface = {
  normal: 'rgb(119,158,253)',
  warning: 'rgb(223,195,83)',
  danger: 'rgb(216,82,97)',
}
